@media (max-width: $screen-lg-max) {
	header, .header-top, .header-bottom {
		width: 350px;
	}
	.product-info .title-product, .product_block_h h3 {
		font-size: 25px;
	}
	.product_block_h {
		margin-bottom: 30px;
	}
	.h1_container {
		padding: 0 35px;
		min-height: 180px;
		h1,.h1 {
			font-size: 34px;
		}
		.catthumb {
			bottom: calc((100% - 300px) / 2);
			right: 175px;
			width: 300px;
		}
		.circles {
			right: 55px;
		}
	}
	#cartbox {
		#cart {
			width: 150px;
			.cart-inner {
				padding-left: 72px;
			}
			.badge {
				left: 36px;
			}
			.carticon {
				left: 20px;
			}
		}
		.product-compare-header {
			width: 150px;
			.badge {
				left: 32px;
			}
			a {
				margin-left: 62px;
			}
		}
	}
	.page-product {
		.title-product {
			padding-top: 0;
		}
		.product-info {
			padding: 30px;
		}
	}
	.product-info {
		.btnrow {
			margin-bottom: 20px;
			&.cart #button-cart {
				margin-right: 20px;
				max-width: 270px;
				height: 50px;
				letter-spacing: 0;
			}
		}
		.quantity-adder {
			height: 48px;
			flex: 1 0 115px;
			max-width: 115px;
			.qty-btns {
				flex: 0 0 35px;
			}
			.add-action {
				height: 20px;
			}
		}
		.attributes {
			margin-bottom: 15px;
		}
		.storage_widget {
			h2 {
				margin-bottom: 20px;
			}
			.sw_bottom {
				padding: 15px;
				.sw_btn {
					margin-left: 30px;
				}
			}
		}
	}
	.product_descr {
		padding: 30px 15px 15px;
	}
	.product_dl ul {
		padding: 20px 0 0 0;
		li {
			flex: 0 1 100%;
			margin-bottom: 20px;
		}
	}
	.product_related {
		.showall {
			height: 40px;
			right: 165px;
		}
		.products-owl-carousel .owl-stage-outer {
			padding: 0;
		}
	}
	.product_videos, .product_related {
		margin-bottom: 30px;
	}
	.btn {
		&-primary {
			padding: 10px 20px;
		}
	}
	.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
		top: -70px;
	}
	.tree-menu > ul > li a {
		padding: 10px 15px;
		width: calc(100% - 20px);
	}
	#bs-megamenu ul {
		padding: 0 15px;
	}
	#searchtop input.form-control {
		padding-left: 15px;
	}
	.logo-store {
		padding: 30px 15px;
	}
	#page {
		padding-left: 350px;
	}
	.tc-phone, .tc-worktime {
		margin: 0 15px;
		padding-right: 35px;
	}
	.tc-worktime {
		.return-call a {
			padding: 10px 15px;
			height: 30px;
		}
	}
	.tc-phone {
		padding-left: 25px;
		margin-bottom: 10px;
		background-position: bottom 3px left;
		&-container .num {
			font-size: 14px;
		}
	}
	.product-grid, .products-owl-carousel {
		.product-block {
			.cart {
				.btn {
					padding: 10px 25px;
				}
			}
			.bottom {
				.compare {
					button {
						padding: 10px 25px;
					}
				}
			}
		}
	}
	.information-warranty .pblock {
		.scmaplist {
			padding: 30px;
		}
		h5 {
			padding: 30px 30px 10px;
		}
	}
	.information-deliveryandpayment {
		.dp_questions {
			h2 {
				flex: 0 1 25%;
				font-size: 26px;
			}
		}
		.flexcont-capt {
			flex: 0 0 34%;
		}
		.flex-contacts .dp_phone .telephone {
			font-size: 15px;
		}
	}
	.information-contact {
		.ct_form .form-group {
			height: 50px;
		}
		.ct_row.ct_top {
			padding: 30px;
		}
	}
	.product-filter {
		padding: 20px 0;
	}
	#powered {
		padding: 0 50px 25px;
		.payment {
			flex: 0 1 650px;
			margin: 0 auto;
		}
		.copyright span {
			margin-left: 30px;
		}
	}
	#main_youget {
		display: block;
		padding: 30px 45px;
		& > div {
			&.rightlist, &.leftlist {
				padding: 0;
			}
		}
		.centerimg {
			float: right;
		}
		ul {
			li {
				margin: 10px 0;
			}
		}
		.videoplay {
			margin: 0;
		}
	}
	.producttabs {
		.product_block_h {
			margin-bottom: 10px;
			ul {
				li {
					a {
						font-size: 20px;
						padding: 20px 30px 20px 0;
					}
					&.active {
						a {
							font-size: 26px;
						}
					}
				}
			}
		}
	}
	.product-label {
		.sale {
			top: 0;
			left: 15px;
		}
	}
	body[class*="page-category"], body[class*="product-special"], body[class*="product-search"] {
		#content {
			padding: 0 40px;
		}
	}
	.product-filter {
		padding: 10px 0;
		.filter-right {
			display: flex;
			.sort {
				padding-left: 0;
			}
			.limit {
				padding-right: 0;
			}
		}
	}
	.buy_products {
		ul {
			li {
				padding: 10px;
				.cart-title {
					margin-left: 10px;
					flex: 1 1 290px;
					.cart-name {
						font-size: 13px;
						padding-right: 15px;
						line-height: 17px;
					}
				}
				.cart-q {
					flex: 0 0 105px;
					height: 60px;
					.input-caption {
						padding: 18px 0;
					}
				}
				.qc {
					margin: 12px 17px 15px 10px;
				}
				.cart-t, .cart-price {
					padding-right: 15px;
					.value {
						font-size: 18px;
						letter-spacing: 0;
						i {
							font-size: 15px;
						}
					}
				}
				.cart-remove {
					flex: 0 0 38px;
				}
			}
		}
	}
	.cart-total-block {
		flex-wrap: wrap;
		padding: 10px 0;
		.total-left {
			padding: 0 30px;
			width: 100%;
			justify-content: space-between;
			.total-element {
				padding: 0;
			}
		}
		.total-right {
			flex: 100%;
			text-align: right;
			padding-top: 15px;
		}
	}
	#checkout-form {
		margin: 15px 0;
	}
}

@media (max-width: 1400px) {
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	#main_cats {
		flex-wrap: wrap;
		& > div {
			flex: 1 1 100%;
		}
		& > a {
			&.for_home {
				.pylesos {
					right: 5%;
				}
				.bg {
					right: 20px;
					left: 40px;
				}
				.ttl {
					left: 60px;
				}
			}
			&.prof {
				.pylesos {
					right: -5%;
				}
				.bg {
					left: 20px;
					right: 40px;
				}
				.ttl {
					left: 40px;
				}
			}
		}
	}
	.footer-center {
		padding: 0 55px 30px;
	}
	.mfilter-content {
		flex-wrap: wrap;
		& > ul {
			padding: 0 15px;
			margin: 0;
			flex: 0 0 100%;
		}
		.mf_sliders > li {
			margin-bottom: 20px;
		}
		.mfilter-checkboxes .mf-svgcb {
			padding-left: 10px;
			padding-bottom: 5px;
		}
	}
}