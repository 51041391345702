.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.quick-view .btnrow.credit_invoice .button-credit {
	margin-left: 0;
}

.price {
	@include h();
	.price-new {
		color: $bk;
	}
	.price-old {
		text-decoration: line-through;
	}
	&.detail {
		margin-bottom: 30px;
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.price-new {
			font-size: 35px;
			flex: 0 1 276px;
			margin-top: -5px;
			.fa-rub {
				font-size: 30px;
			}
		}
		.price-old {
			font-size: 20px;
			color: $tg;
			text-decoration: none;
			.caption {
				font-family: $f;
				font-weight: 300;
				font-size: 12px;
			}
			.special-price {
				text-decoration: line-through;
			}
		}
		ul {
			margin: 0;
		}
	}
}

.product-label {
	color: $product-label-color;
	font-size: $font-size-base - 2;
	font-weight: bold;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	&.bts {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 46px;
		height: 20px;
		position: absolute;
		top: 10px;
		background-color: $theme-color;
		right: 10px;
		border-radius: 2px;
		line-height: 20px;
		.product-label-special {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	&.sale {
		text-align: center;
		position: absolute;
		top: 30px;
		left: 30px;
		width: 85px;
		height: 85px;
		z-index: 99;
		@include rounded-corners(100%);
		svg {
			.circle {
				fill: $tc;
			}
		}
		text {
			font-size: 9px;
			fill: $tg;
		}
	}
}

.product-info {
	.stickers {
		position: absolute;
		top: 0;
		right: 0;

		span {
			display: block;
		}
	}
	.warranty10yers span {
		width: 85px;
		height: 85px;
		background-image: url(/image/warranty10.svg);
	}
}

.stickers {
	&.markdown-product {
		position: absolute;
		top: 10px;
		left: 10px;
		right: unset;

		color: $wh;
		background: $red;
		border: 1px solid $red;
		@include rounded-corners(4px);
		text-transform: uppercase;
		padding: 4px;
		z-index: 9;
	}
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;
	.desc-header-wrapper {
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			padding: 15px;
			text-transform: uppercase;
			font-size: 18px;
			position: relative;
			top: 1px;
			display: inline-block;
		}
	}
	#tab-description {
		padding: 0 15px;
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $bk !important;
		}
	}
	#tab-cert {
		img {
			max-width: 50%;
		}
	}
}