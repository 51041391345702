.grecaptcha-badge {
	visibility: hidden;
}
button {
	&:active, &:focus {
		outline: none !important;
	}
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $theme-color;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
	}
	button {
		border: none;
		&:focus, &:active {
			outline: none !important;
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 100%;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
				text-decoration: none;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 14px;
				@include h();
				padding: 10px 0 30px 0;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				@include h();
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				display: inline-block;
				padding: 12px 25px;
				height: 40px;
			}
		}
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
	.product_block_h {
		margin-bottom: 30px;
		padding-top: 10px;
		ul {
			li {
				a {
					font-size: 25px;
					@include h();
					color: $tg;
					line-height: 25px;
					padding: 20px 40px 20px 0;
				}
				&.active {
					a {
						color: $bk;
						text-decoration: none;
						font-size: 35px;
					}
				}
			}
		}
	}
	.owl-carousel {
		.owl-stage {
			padding: 20px 0;
		}
	}
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: inline-block;
		text-align: left;
		font-size: 14px;
		color: $blue;
		font-weight: 300;
		padding-left: 22px;
		text-decoration: underline;
	}
	a {
		font-size: 18px;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			border: 3px solid $bc;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.95);
			transform-origin: center center;
			width: 100%;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
		}
		&.radio input:checked ~ .input-control__indicator {
			border-color: $bk;
			&:after {
				@include opacity(1);
			}
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $blue;
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 3px $bc solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			width: 100%;
			height: 100%;
			background: $tc;
		}
		&.checkbox input:checked ~ .input-control__indicator {
			border: 3px $bk solid;
			&:after {
				@include opacity(1);
			}
		}
	}
}

.category_widget {
	a {
		.type1 {
			height: 500px;
			background: transparent center no-repeat;
			background-size: cover;
			margin-bottom: 30px;
			position: relative;
			h2 {
				margin: 0;
				position: absolute;
				height: 70px;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, .7);
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				font-size: 20px;
				padding: 23px 0;
				display: inline-block;
			}
		}
	}
}

.product-compare {
	.comparetable {
		.table {
			& > thead, & > tbody {
				& > tr {
					& > td, & > th {
						padding: 10px;
					}
				}
			}
		}
		b, strong {
			@include h();
		}
		.com_price {
			@include h();
			.price-old {
				color: $tg;
				text-decoration: line-through;
				margin-right: 15px;
			}
			i {
				font-size: 13px;
			}
		}
		overflow-x: auto;
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			padding: 10px 0;
			margin: 5px auto 5px auto;
			display: block;
			width: 150px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 50px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	background-color: $tc;
	text-indent: -9999px;
	z-index: 999;
	border: none;
	border-radius: 100%;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, .2);
	@include transition(200ms all ease-in-out);
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -8px;
		margin-top: -12px;
		height: 0;
		width: 0;
		border: 8px solid transparent;
		border-bottom-color: $bk;
	}
	&:hover {
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		margin: 0;
		width: auto;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.owl-carousel .owl-nav {
	.owl-prev, .owl-next {
		position: absolute;
		top: -83px;
		font-size: 32px;
		line-height: 1;
		background: $wh;
		color: $bk;
		border-radius: 100%;
		padding: 10px 24px;
		width: 54px;
		height: 54px;
		opacity: .5;
		@include fade();
		box-shadow: 0 0 25px rgba(0, 0, 0, .2);
		&:hover {
			opacity: 1;
		}
	}
	.owl-prev {
		right: 80px;
		padding-left: 20px;
	}
	.owl-next {
		right: 0;
	}
}

.mfilter-box {
	.ui-slider-handle {
		@include user-select(none);
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
	display: inline-block;
	flex: 1 1 auto;
	margin-left: 25px;
	.label {
		@include h();
		font-size: 12px;
	}
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border-radius: 0;
	position: relative;
	overflow: hidden;
	.label {
		font-size: 12px;
		font-weight: 700;
		text-align: left;
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		height: 30px;
		color: $bk;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1.1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	left: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		font-size: 12px;
		@include h();
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $blue;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.container-inside {
	margin-right: 10px;
}

.holiday_works {
	text-align: center;
	margin-top: 10px;
	border: none !important;
	margin-left: auto !important;
	display: block;
	position: absolute;
    left: 20px;
    top: -10px;
    z-index: 999;
}

body.ie {
	#main_youget > div {
		flex: 1 0 35%;
		&.centerimg {
			flex: 0 0 230px !important;
		}
	}
	.payment-img img {
		width: 100%;
	}
	#powered, .product-info .filter_icons svg {
		height: 50px;
	}
	.form-control {
		padding: 7px 15px;
	}
	.mfilter-attributes > .mfilter-options {
		flex: 1;
	}
	#cartbox .product-compare-header svg {
		top: 2px;
	}
	.callback_form .cb_input {
		height: 66px;
		input {
			margin: 12px 20px;
		}
	}
	.flex-contacts svg, #toggle_filters svg {
		height: 16px;
	}
	.information-deliveryandpayment .dp_questions .flex-contacts {
		flex: 1;
	}
	.information-deliveryandpayment .dp_questions .flexcont-capt {
		flex: 1 0 35%;
	}
	.mfilter-content > ul {
		flex: 0 0 48%;
	}
}

body.webkit {
	@media (max-width: 1599px) {
		.product-info .quantity-adder .add-action {
			height: 18px;
		}
	}
	@media (max-width: 991px) {
	/*	.search_block {
			left: 55px;
			position: relative;
			width: calc(100% - 55px);
		}
		#searchtop  {
			top: 11px;
			&.srch {
				width: 100%;
			}
			input.form-control {
				font-size: 16px;
				right: 38px;
				position: absolute;
			}
		}*/
	}
}

body.ios {
	@media (max-width: 991px) {
	/*	.search_block {
			left: 55px;
			position: relative;
			width: calc(100% - 55px);
		}
		#searchtop  {
			top: 11px;
			&.srch {
				width: 100%;
			}
			input.form-control {
				font-size: 16px;
				right: 38px;
				position: absolute;
			}
		}*/
	}
	.fancybox-overlay {
		z-index: unset !important;
	}
}

.tc-phone-container a {
	color: $bk !important;
	text-decoration: none !important;
}

/*.common-home .fancybox-type-iframe .fancybox-skin {
	background: #000;
}*/