@charset "utf-8";
.mfilter-content {
	display: flex;
	& > ul {
		padding: 15px;
		list-style: none;
		flex: 0 0 50%;
		& > li {
			flex: 1 1 100%;
		}
	}
	&#main1 {
		& > ul {
			&.mf_checkboxes {
				display: none;
			}
			flex: 0 0 100%;
		}
	}
	.mfilter-disabled {
		display: none;
	}
	.mf_sliders {
		.mfilter-options {
			background: transparent;
			border-radius: 8px;
			.mfilter-option {
				border-radius: 7px;
				border: 1px solid $bc;
				box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
			}
			.mfilter-slider-inputs {
				&.noUi-horizontal {
					height: 50px;
					padding: 0 70px;
					background: transparent;
					.noUi-base:after {
						content: 'Тяните ползунок';
						position: absolute;
						top: calc(50% - 10px);
						font-size: 12px;
						height: 20px;
						display: inline-block;
						width: 100%;
						text-align: center;
						color: $tg;
					}
					.noUi-connect {
						background: transparent;
					}
					.noUi-tooltip {
						white-space: normal;
						height: 40px;
						width: 70px;
						bottom: 5px;
						top: 5px;
						padding: 7px 5px;
						transform: none;
						box-shadow: 1px 1px 8px rgba(0, 0, 0, .05);
						cursor: pointer;
						@include h();
						&:active {
							background: $tc;
						}
					}
					.noUi-handle {
						top: -1px;
						bottom: 0;
						border: none;
						background: transparent;
						box-shadow: none;
						&:before, &:after {
							content: none;
						}
						&.noUi-handle-lower {
							.noUi-tooltip {
								left: -50px;
							}
						}
						&.noUi-handle-upper {
							.noUi-tooltip {
								right: -48px;
								left: auto;
							}
						}
					}
				}
			}
			.mfilter-advice {
				font-size: 12px;
				line-height: 20px;
				text-align: center;
				color: $tg;
				position: absolute;
				width: 100%;
				top: calc(50% - 10px);
				left: 30px;
			}
		}
		& > li {
			display: flex;
			margin-bottom: 25px;
			align-items: center;
			.mfilter-heading-text {
				font-size: 12px;
				@include h();
				color: $mg;
				flex: 0 0 190px;
				padding-right: 15px;
				span {
					display: inline-block;
					text-align: left;
				}
			}
			.mfilter-content-opts {
				flex: 1;
			}
		}
	}
	.mfilter-checkbox {
		display: flex;
		.mfilter-heading-text {
			@include h();
			font-size: 12px;
			color: $mg;
			flex: 0 0 150px;
			text-align: center;
			padding: 7px 30px 0 0;
			span {
				display: inline-block;
				text-align: left;
			}
		}
	}
	.ui-slider-horizontal {
		height: 50px;
	}
	.mfilter-checkboxes {
		display: flex;
		flex-wrap: wrap;
		.mf-svgcb {
			padding-left: 15px;
			padding-bottom: 10px;
			label {
				position: relative;
				cursor: pointer;
				width: 50px;
				height: 50px;
				margin: 0;
				input {
					position: absolute;
					width: auto !important;
					visibility: hidden;
					opacity: 0;
					& ~ svg {
						position: absolute;
						top: 0;
						left: 0;
						width: 50px;
						height: 50px;
						background: $wh;
						@include fade();
						path, line, circle {
							stroke: $bk;
							stroke-width: 1.5px;
							vector-effect: non-scaling-stroke;
							fill: none;
							stroke-linecap: round;
						}
					}
					&:checked ~ svg {
						background: $bk;
						path, line, circle {
							stroke: $tc;
						}
					}
				}
			}
		}
	}
	.ui-widget-content {
		background: transparent;
		border: none;
		margin: 0 76px 0 15px;
	}
	.ui-widget-header {
		background: transparent;
	}
}
