/* links style */
ul {
	&.links {
		padding: 0;
		position: relative;
		margin: 0;
		li {
			display: inline;
			line-height: normal;
			position: relative;
			a {
				padding-left: 10px;
				color: $text-color;
				i {
					margin-right: 5px;
				}
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	&.list {
		padding: 0;
		position: relative;
		margin: 0;
		li {
			list-style: none;
			line-height: 30px;
			a {
				color: $text-color;
				&:hover {
					color: $tc;
				}
			}
		}
	}
}

.list-group-item {
	ul {
		padding-left: 20px;
		li {
			list-style: none;
			padding: 8px 0;
			a {
				color: $theme-color-tertiary;
			}
		}
	}
}

div.required .control-label:before {
	color: #f00;
	content: "* ";
	font-weight: bold;
}

.noborder {
	.box {
		border: none !important;
	}
}

.white {
	color: $white;
}

.red {
	color: #f44336;
	line-height: 20px;
}

.table-responsive {
	.btn-primary {
		padding: 0 14px;
	}
}