.autosearch-wrapper.input-group {
	margin-top: 17px;
}

#content {
	.sphinxsearch {
		width: 350px;
		left: 15px;
	}
}

.sphinxsearch {
	width: 100%;
	right: 0;
	left: 0;
	position: absolute;
	top: 100%;
	margin-top: 1px;
	border: none;
	z-index: 99999;
	background: $wh;
	border-radius: 0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	overflow-y: auto;
	max-height: calc(100vh - 126px);
	& > a {
		transition: 0.2s all;
		display: inline-block;
		width: 100%;
		padding-bottom: 5px;
		cursor: pointer;
		line-height: 1;
		position: relative;
		border-bottom: 1px #ebebeb solid;
		text-decoration: none;
		&:first-child {
			border-top: none;
		}
		&:last-child {
			border-bottom: none;
		}
		img {
			float: left;
			margin: 0 10px 0 0;
			padding: 5px;
		}
	}
	.categories, .products {
		span {
			display: block;
			padding: 10px 0;
			font-size: 14px;
			text-align: center;
			background: #eee;
		}
	}
	.categories {
		a {
			text-transform: uppercase;
			text-indent: 10px;
		}
	}
}

.product_sp {
	width: calc(100% - 15px);
	font-size: 14px;
	margin: 0;
	position: relative;
	top: 6px;
	padding-bottom: 30px;
	@include h();
}

.price_sp {
	width: calc(100% - 95px);
	font-size: 16px;
	position: absolute;
	bottom: 7px;
	left: 85px;
	font-weight: 600;
	color: $bk;
	.old {
		font-size: 14px;
		text-decoration: line-through;
		margin-right: 7px;
		color: #777;
	}
}

.sphinx-viewall {
	padding: 6px 25px;
	margin: 10px 0;
	display: inline-block;
	text-align: center;
	width: 100%;
	&:hover {
		background: $white;
	}
	#view-all {
		padding: 10px 30px;
		display: inline-block;
		font-size: 18px;
		height: auto;
		background: $theme-color;
		line-height: 36px;
		text-decoration: none;
		border-radius: 10px;
		box-shadow: 0 5px 0 -2px rgba(0, 0, 0, .3);
		color: $bk;
		@include h();
		@include fade();
		&:hover {
			box-shadow: 0 0 0 -2px rgba(0, 0, 0, .3);
		}
	}
	&-no-res {
		font-weight: bold;
		text-align: center;
		height: 30px;
		color: $black;
		width: 100%;
		line-height: 30px;
	}
}

@media (max-width: 380px) {
	.sphinxsearch {
		width: calc(100vw - 40px);
	}
}