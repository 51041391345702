.breadcrumbs {
	margin-bottom: 0;
	list-style: none;
	.breadcrumb {
		display: block;
		padding: 15px 0;
		margin: 0;
		> li {
			display: inline;
			+ li:before {
				content: "#{$breadcrumb-separator}\00a0";
				padding: 0 5px;
				color: $text-color;
			}
		}
		> .active {
			font-weight: 600;
		}
		a {
			color: $dg;
			text-decoration: none;
		}
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
}