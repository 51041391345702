$tc: #fff103 !default;
$bk: #0b0b0b !default;
$wh: #ffffff !default;

$blue: #47afcf !default;
$red: #fe5718 !default;
$y1: #f0df14 !default;
$y2: #b5ac53 !default;

$bc: #e5e5e5 !default;
$lg: #f5f5f5 !default;
$tg: #b0b0b0 !default;
$mg: #999 !default;
$dg: #67747a !default;

$h: 'Gotham', sans-serif !default;
$f: 'HelveticaNeue', sans-serif !default;
$fa: 'FontAwesome';

/************  MIXINS   **************/

@mixin fade() {
	@include transition(all 0.2s ease-in-out);
}

@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@mixin round() {
	@include rounded-corners(100%);
}

@mixin h() {
	text-transform: uppercase;
	font-weight: 700;
	font-family: $h;
}

@mixin hl() {
	font-weight: 700;
	font-family: $h;
}
