.btn {
	margin-bottom: 0;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background: none;
	border: none;
	white-space: nowrap;
	@include user-select(none);
	position: relative;
	display: flex;
	justify-content: center;
	height: 100%;
	text-decoration: none !important;
	span {
		align-self: center;
		@include h();
		text-decoration: none !important;
	}
	svg {
		width: 100%;
		height: 100%;
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		overflow: visible;
		path, use {
			@include fade();
			vector-effect: non-scaling-stroke;
		}
	}
	&, &:active, &.active {
		&:focus, &.focus {
			@include tab-focus;
		}
	}

	&-default {
		span {
			color: $blue;
			text-decoration: none;
		}
		path {
			fill: none;
			stroke: $blue;
			stroke-width: 1px;
		}
		&.btn-cart {
			span, a {
				color: $bk;
			}
			path {
				stroke: $bk;
			}
		}
	}

	&-primary {
		padding: 15px 45px;
		span {
			color: $bk;
			position: relative;
		}
		path {
			transform-origin: bottom;
		}
		.main {
			fill: #f0df14;
			stroke: #e8d81c;
			stroke-width: 1px;
		}
		.shadow {
			fill: $y2;
			stroke: none;
		}
		&:hover {
			.shadow {
				transform: translateY(-4px);
				fill: #e8d81c;
			}
		}
	}

	&:hover, &:focus, &.focus {
		text-decoration: none;
	}

	&:active, &.active {
		outline: 0;
		background-image: none;
	}

	&.disabled, &[disabled], fieldset[disabled] & {
		cursor: default;
		pointer-events: none;
		@include opacity(.65);
		@include box-shadow(none);
	}
}

// Danger and error appear as red
.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
	// color: $brand-success;

	&, &:active, &.active, &[disabled], fieldset[disabled] & {
		background-color: transparent;
		@include box-shadow(none);
	}
	&:hover, &:focus {
		color: $link-color;
		text-decoration: none;
		background-color: transparent;
	}
	&[disabled], fieldset[disabled] & {
		&:hover,
		&:focus {
			color: $btn-link-disabled-color;
			text-decoration: none;
		}
	}
}

// Block button
// --------------------------------------------------

.btn-block {
	display: block;
	width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
	margin-top: 5px;
}

// Specificity overrides
input[type="submit"], input[type="reset"], input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
