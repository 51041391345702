body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

#searchtop {
	border-bottom: 1px $bc solid;
	input.form-control {
		background: transparent;
		border-radius: 0;
		border: none;
		font-style: normal;
		color: $bk;
		height: 63px;
		font-size: 14px;
		@include h();
		padding: 15px 15px 15px 45px;
	}
	.btn-search {
		display: inline-block;
		background: $tc;
		border: none;
		cursor: pointer;
		@include transition(background 0.2s ease-in-out);
		@include rounded-corners(100%);
		@include size(35px, 35px);
		padding: 9px 10px;
		margin-right: 23px;
		&:hover {
			background: $y1;
		}
	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

.has-error .form-control {
	box-shadow: none;
}

#cartbox {
	position: absolute;
	right: 25px;
	top: 20px;
	z-index: 99;
	.headercart {
		position: absolute;
		right: 0;
		top: 0;
	}
	#cart {
		font-family: $h;
		width: 205px;
		height: 75px;
		.cart-inner {
			width: 100%;
			text-align: left;
			padding-left: 92px;
		}
		&.open .dropdown-toggle {
			box-shadow: none;
		}
		a {
			display: inline-block;
			position: relative;
		}
		.badge {
			position: absolute !important;
			top: 14px !important;
			left: 45px;
			padding: 3px;
			min-width: 22px;
			height: 22px;
			line-height: 15px;
			z-index: 99;
			color: $wh;
			background: $blue;
		}
		.wrap-cart {
			display: inline-block;
			color: $black;
			height: 100%;
			span {
				position: relative;
				top: 13px;
			}
			.cart-total {
				font-weight: bold;
				text-transform: uppercase;
				display: inline-block;
				font-size: 12px;
				text-align: left;
				vertical-align: text-top;
			}
		}
		.carticon {
			width: 28px;
			height: 28px;
			fill: $bk;
			position: absolute;
			top: 23px;
			left: 32px;
		}
		.price-cart {
			color: $theme-color;
		}
	}
	.product-compare-header {
		position: absolute;
		top: 90px;
		right: 0;
		border: none;
		z-index: 999;
		text-align: left;
		@include transition(0.2s all);
		font-size: 12px;
		svg {
			width: 26px;
			position: absolute;
			top: 8px;
			left: 0;
		}
		#backwall {
			position: absolute;
			width: 70px;
			height: 40px;
			background: #fff103;
			opacity: 0.7;
			border-radius: 7px;
			left: -10px;
			display: none;
		}
		a {
			color: $bk;
			display: inline-block;
			margin-left: 84px;
			text-decoration: none;
		}
		#compare-total {
			display: inline-block;
			width: 100px;
		}
		.badge {
			color: $white;
			position: absolute !important;
			top: 9px !important;
			left: 52px;
			padding: 3px;
			width: 22px;
			height: 22px;
			line-height: 15px;
			z-index: 99;
			font-family: $h;
			background: $bk;
			@include round();
		}
	}
}

.mobphone {
	display: none;
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

.buy_products {
	ul {
		padding: 0;
		list-style: none;
		@include flex();
		flex-wrap: wrap;
		li {
			flex: 1 1 100%;
			margin-bottom: 20px;
			padding: 10px 10px 10px 30px;
			@include flex();
			@include rounded-corners(7px);
			justify-content: space-between;
			align-items: center;
			background: $wh;
			box-shadow: 0 15px 54px rgba(0, 0, 0, .05);
			.cart {
				&-img {
					flex: 1 1 120px;
				}
				&-title {
					flex: 1 1 380px;
					.cart-name {
						font-size: 15px;
						padding-right: 30px;
						a {
							color: $blue;
							text-decoration: underline $blue;
							@include h();
						}
					}
					.cart-model {
						color: $dg;
						font-size: 13px;
					}
				}
				&-q {
					flex: 0 0 155px;
					margin-right: 30px;
					position: relative;
					display: flex;
					justify-content: space-between;
					* {
						position: relative;
					}
					.qc {
						color: $tg;
						font-size: 26px;
						display: inline-block;
						width: 16px;
						text-align: center;
						margin: 15px 25px 15px 10px;
						line-height: 16px;
						span {
							cursor: pointer;
							line-height: 15px;
							display: block;
							padding-bottom: 6px;
						}
					}
					.cart-input {
						flex: 1 1 auto;
						text-align: center;
					}
					input {
						border: none;
						font-size: 14px;
						@include hl();
						text-align: right;
						padding-right: 20px;
						background: transparent;
					}
					.input-caption {
						padding: 23px 0;
						margin-left: -20px;
						font-weight: 700;
						font-family: $h;
						display: inline-block;
					}
					svg {
						height: 100%;
						width: 100%;
						position: absolute;
						stroke: $bc;
						fill: none;
						stroke-width: 2px;
						path {
							vector-effect: non-scaling-stroke;
						}
					}
				}
				&-price, &-t {
					padding-right: 30px;
					flex: 1 1 130px;
					.name {
						color: $dg;
						font-size: 13px;
					}
					.value {
						@include hl();
						font-size: 20px;
						letter-spacing: 1px;
						white-space: nowrap;
						i {
							font-size: 18px;
						}
					}
				}
				&-remove {
					flex: 0 0 30px;
					text-align: center;
					a {
						@include size(24px, 24px);
						display: inline-block;
					}
					svg {
						stroke: $tg;
					}
				}
			}
		}
	}
}

#coupon_form {

	h4 {
		text-transform: uppercase;
		cursor: pointer;
	}
	#coupon_container {
		margin-top: 15px;
	}
	.form-group {
		position: relative;
		height: 50px;
		display: inline-block;
		align-items: center;
		margin-right: 15px;
		input {
			position: relative;
			width: 200px;
			height: 100%;
			padding: 12px 15px;
		}
	}
	#button-coupon {
		display: inline-block;
		height: 47px;
		padding: 10px 25px;
		vertical-align: baseline;
	}
	#coupon_error {
		.aleert {
			margin: 0 !important;
		}
	}
}

.cart-total-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
	color: $tg;
	b {
		margin-left: 20px;
		@include hl();
		color: $bk;
	}
	.total-left {
		font-size: 16px;
		display: flex;
		.total-element {
			padding: 0 30px;
		}
	}
	.total-right {
		font-size: 22px;
		padding: 0 30px;
	}
}

#checkout-f {
	font-size: 35px;
	margin-bottom: 45px;
	text-transform: uppercase;
}

#checkout-form {
	background: $wh;
	margin: 45px 0;
	padding: 45px;
	@include rounded-corners(7px);
	box-shadow: 0 15px 54px rgba(0, 0, 0, .05);
	.cf_contacts, .cf_address, #ip_fields {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		width: 100%;
	}
	.cf_contacts {
		.form-group {
			width: calc((100% / 3) - 20px);
		}
	}
	.cf_address {
		margin-top: 30px;
		.form-group {
			width: calc((100% / 2) - 20px);
		}
	}
	.cf_radio {
		margin-top: 45px;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		& > div {
			width: calc(50% - 50px);
		}
		#shipping_methods {
			.radio_custom .input-control {
				display: block;
				padding-right: 60px;
			}
			.cost {
				color: $tg;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		.radio {
			margin: 5px 0;
		}
		h3 {
			text-transform: uppercase;
			font-size: 14px;
			margin-bottom: 10px;
		}
		.tk-comment {
			font-size: 14px;
			color: $dg;
		}
	}
	.form-group {
		position: relative;
		height: 67px;
		display: inline-block;
		align-items: center;
		&.buy_comment {
			display: flex;
		}
		&.has-error {
			margin-bottom: 20px;
			.text-danger {
				br {
					display: none;
				}
			}
		}
		input {
			padding: 0 30px;
			position: relative;
			height: 100%;
		}
		label {
			display: none;
		}
		svg {
			height: 100%;
			width: 100%;
			position: absolute;
			stroke: $bc;
			fill: none;
			stroke-width: 2px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			path {
				vector-effect: non-scaling-stroke;
			}
		}
	}
	#is_ip {
		height: 40px;
		label {
			display: inline-block;
		}
		.radio_custom {
			.input-control {
				margin: 1px 15px 1px 0;
				padding-left: 5px;
			}
		}
	}
	#ip_fields {
		.form-group {
			width: calc(50% - 20px);
		}
	}
}

.checkout-buy .form-group svg {
	height: 100%;
	width: 100%;
	position: absolute;
	stroke: $bc;
	fill: none;
	stroke-width: 2px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	path {
		vector-effect: non-scaling-stroke;
	}
}

.cart_buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#button-order {
	padding: 29px 82px;
}

#checkout-form {
	.has-feedback .form-control-feedback {
		top: 26px;
		right: 10px
	}
	#payment-form {
		display: none
	}
}

.callback_form {
	background: rgba(0, 0, 0, .4);
	z-index: 10010;
	h2 {
		text-transform: uppercase;
		font-size: 30px;
		text-align: center;
	}
	.modal-body, .modal-header {
		padding: 0;
	}
	.modal-header {
		border: none;
	}
	.modal-content {
		padding: 30px 60px 45px 60px;
		border-radius: 15px;
	}
	.cb_input {
		position: relative;
		display: inline-block;
		width: 100%;
		margin: 15px 0;
		svg {
			height: 100%;
			width: 100%;
			position: absolute;
			stroke: $bc;
			fill: $wh;
			stroke-width: 2px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			path {
				vector-effect: non-scaling-stroke;
			}
		}
		input {
			@include placeholder($bc);
			padding: 20px 30px;
			height: auto;
			color: $bk;
			font-size: 16px;
			position: relative;
		}
	}
	.btn {
		margin: 15px auto 0 auto;
	}
	.close {
		height: 20px;
		width: 20px;
		position: absolute;
		top: 45px;
		right: 42px;
		svg {
			width: 100%;
			path {
				stroke: $tg;
				stroke-width: 2px;
			}
		}
	}
	.btn-container {
		text-align: center;
	}
}

#main_youget {
	display: flex;
	padding: 60px 45px;
	background: $wh;
	.product_block_h {
		margin-bottom: 60px;
		padding-top: 30px;
		height: auto;
	}
	& > div {
		flex: 1 0 calc(50% - 250px);
		&.leftlist {
			padding-right: 50px;
		}
		&.centerimg {
			flex: 0 0 250px;
			order: 2;
		}
		&.rightlist {
			order: 3;
			padding-left: 50px;
		}
	}
	ul {
		li {
			display: flex;
			margin: 25px 0;
			align-items: center;
		}
		list-style: none;
		padding: 0;
		.numb {
			flex: 0 0 100px;
			svg {
				width: 60px;
				stroke: $blue;
				fill: none;
				vector-effect: non-scaling-stroke;
				text {
					text-anchor: middle;
					@include hl();
					font-size: 24px;
					fill: $blue;
				}
			}
		}
		.txt {
			font-size: 18px;
			.phone {
				display: block;
				@include hl();
				svg {
					width: 10px;
					height: 10px;
				}
			}
		}
	}
	.videoplay {
		display: flex;
		align-items: center;
		margin-top: 54px;
		a {
			flex: 0 0 100px;
			svg {
				width: 60px;
				path {
					@include fade();
				}
				.fatc {
					fill: none;
					stroke-width: 5px;
					stroke: $blue;
				}
				.thc {
					fill: none;
					stroke-width: 2px;
					stroke: $blue;
					transform-origin: center;
				}
				.tri {
					fill: $blue;
					stroke: none;
				}
			}
			&:hover {
				transform: none;
				.thc {
					animation-name: videoplay;
					animation-duration: .5s;
					animation-iteration-count: infinite;
				}
				.tri {
					fill: darken($blue, 5%);
				}
			}
		}
	}
}

#main_cats {
	display: flex;
	& > a {
		flex: 1 1 50%;
		position: relative;
		height: 440px;
		&.for_home {
			.bg {
				background: linear-gradient(to right, $tc, #fcfc9e 80%, #fcfa89 100%);
				svg {
					stroke: #ddd;
				}
			}
			.ttl {
				color: $bk;
			}
		}
		&.prof {
			.pylesos {
				right: 10%;
			}
			.bg {
				background: linear-gradient(to right, #0f0f0f, #161616);
				svg {
					stroke: #333;
				}
			}
			.ttl {
				color: $wh;
			}
		}
		.pylesos {
			position: absolute;
			right: 20%;
			bottom: 10px;
		}
		.bg {
			border-radius: 5px;
			position: absolute;
			top: 80px;
			left: 80px;
			bottom: 80px;
			right: 80px;
			overflow: hidden;
			height: 280px;
			svg {
				fill: none;
				stroke-width: 1px;
				vector-effect: non-scaling-stroke;
				height: 100%;
				position: absolute;
				right: 0;
			}
		}
		.ttl {
			font-size: 22px;
			@include h();
			position: absolute;
			left: 120px;
			bottom: 120px;
		}
	}
}

.callback_widget {
	padding: 80px 0 80px calc(50% - 100px);
	background: url('/image/i/guy.jpg') $wh no-repeat left bottom;
	background-size: 500px;
	.product_block_h {
		padding-top: 35px;
		margin-bottom: 15px;
		height: auto;
		h2 {
			font-size: 35px;
		}
	}
	.cw_subheader {
		font-size: 16px;
		color: $tg;
		max-width: 420px;
	}
	.callback_form {
		background: transparent;
		z-index: 100;
		max-width: 530px;
		.cb_input {
			margin: 7px 0;
			input {
				height: 70px;
				position: relative;
			}
			button {
				margin: 0;
				width: 100%;
				height: 70px;
			}
		}
	}
	.cw_phone {
		margin-top: 15px;
		max-width: 530px;
		text-align: center;
		span {
			font-size: 13px;
			color: $tg;
			vertical-align: top;
			margin-bottom: 15px;
			display: block;
		}
		h2 {
			font-size: 33px;
			display: block;
		}
	}
	& > h5 {
		display: none;
	}
	&.sent {
		background: white;
		padding: 50px 0;
		& > div {
			display: none;
		}
		& > h5 {
			display: block;
			text-align: center;
			font-size: 20px;
		}
	}
}

.ymap_widget {
	position: relative;
	ymaps[class*="events-pane"] {
		box-shadow: 0 30px 20px -20px rgba(0, 0, 0, .4) inset;
		background-image: radial-gradient(ellipse at 35% 30%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
		background-size: 500px 500px;
		background-repeat: no-repeat;
	}
	.product_block_h {
		position: absolute;
		left: 80px;
		top: 120px;
		height: auto;
		padding-top: 30px;
		line-height: 1.3;
		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}

.fancybox-overlay {
	z-index: 18000 !important;
	.fancybox-opened {
		z-index: 18050 !important;
	}
}

.fancybox-skin {
	background: $wh;
}

.page-themecontrol-product {
	header, #cartbox, #footer, #powered, .product-grid, .product-box-bottom, .rating, .offcanvas-inner, .box-product-infomation, .zoomContainer {
		display: none;
	}
	#page {
		background: #fff;
		padding: 0;
		.product-info {
			& > .row {
				margin: 0;
			}
			.btn-compare {
				flex: 0;
				margin-right: 30px;
			}
		}
		a.info_colorbox {
			cursor: default;
		}
	}
	#sys-notification {
		position: inherit;
	}
}

#finded-low-cost-form, #request-on-order-form, #reserve_form_one_click {
	h3 {
		text-transform: uppercase;
		text-align: center;
		font-size: 20px;
		margin: 15px 15px 0;
	}
	form {
		display: flex;
		flex-wrap: wrap;
	}
	.close {
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.form-group {
		position: relative;
		height: 45px;
		display: inline-block;
		align-items: center;
		flex: 1 0 100%;
		margin: 15px 0 0 0;
		&.has-error {
			margin-bottom: 8px;
		}
		.error {
			padding-left: 15px;
			color: $red;
		}
		svg {
			height: 100%;
			width: 100%;
			position: absolute;
			stroke: $bc;
			fill: none;
			stroke-width: 1.5px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		label {
			display: none;
		}
		input {
			padding: 0 15px;
			position: relative;
			height: 100%;
		}
	}
	.buttons {
		width: 100%;
		.btn-primary {
			margin: 0 auto;
		}
	}
}

#finded-low-cost-form {
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.5);
	.modal-dialog {
		top: 10%;
	}
	button, .btn {
		outline: none;
	}
}

#request-on-order-form {
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.5);
	textarea {
		resize: none;
	}
	h3 {
		width: 100%;
	}
	h4.request__text {
		margin: 80px 15px !important;
		text-align: center;
		@media (max-width: 468px) {
			margin: 40px 15px !important;
			font-size: 16px !important;
		}
	}
	.form-group.requisit {
		height: auto;
		textarea {
			border-radius: 10px;
			background: $lg;
		}
	}
	.buttons {
		margin-top: 20px;
		padding-bottom: 0;
	}
	legend {
		margin: 0;
	}
}

#reserve_form_one_click {
	h3 {
		text-transform: uppercase;
	}
}