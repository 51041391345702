@media (max-width: 570px) {
	h2, .h2 {
		font-size: 24px;
	}
	#powered {
		flex-direction: column;
		gap: 8px;

		& > .container {
			padding: 0;
		}
		& > img, .copyright, .payment {
			flex: unset;
		}
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
			}
		}
	}
	.payment2row {
		display: block;
	}

	.header-top .topcontacts.worktime {
		display: none;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
		margin-right: 15px;
	}
	.product-grid {
		.product-block {
			width: calc(100% - 20px);
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.paging {
		display: block;
	}
	.pagination {
		padding: 0 10px;
		& > li {
			width: 30px;
			margin: 8px 8px 0 0;
			& > a, & > span {
				line-height: 27px;
				width: auto;
				height: auto;
				padding: 5px 7px;
			}
		}
	}
	.product-filter .filter-right > div {
		display: none;
	}
	.price.detail {
		margin-bottom: 10px;
		.price {
			align-items: center;
			justify-content: center;
		}
		.price-new {
			flex: 0 0 auto;
		}
		.price-old {
			.caption {
				display: none;
			}
		}
		li {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	.btn-compare {
		margin-top: 10px;
	}
	.search_block {
		.dropdown-menu {
			&.autosearch {
				right: -55px;
				width: calc(100vw - 30px);
			}
		}
	}
	#checkout-form {
		.cf_contacts, #ip_fields {
			justify-content: center;
			margin-bottom: 0;
			flex-wrap: wrap;
			.form-group {
				width: 100%;
				max-width: 300px;
				margin-bottom: 20px;
				&.requied.has-error {
					margin-bottom: 35px;
				}
			}
		}
	}
	.information-contact {
		.ct_email {
			margin-top: 10px;
		}
		ul.rekvizit {
			padding: 0;
			margin-top: 15px;
		}
		.ct_row {
			&.ct_top {
				padding: 15px;
			}
			.ct_col {
				flex: 0 1 100%;
			}
		}
		.ct_nav .ct_col:last-child {
			padding-left: 0;
		}
	}
	.information-aboutus .about_block {
		.phone {
			display: inline-block;
			margin-left: 20px;
			padding-left: 25px;
			background-position: bottom 2px left;
		}
		.abt_img {
			margin: 0 0 10px 10px;
			&:nth-child(2) {
				display: none;
			}
		}
		&.dealer, &.withmap {
			flex-wrap: wrap;
			> div, div.call, div.founded {
				flex: 1 1 100%;
				margin: 0;
			}
		}
	}
	.information-warranty .pblock.cities .wt ul li {
		padding: 10px 15px;
		align-self: center;
		&:first-child {
			flex: 0 0 140px;
		}
	}
	#main_youget {
		padding: 15px;
		.product_block_h {
			margin-bottom: 15px;
			h2 {
				span {
					display: inline;
				}
			}
			ul {
				.numb {
					flex: 0 0 70px;
					svg {
						width: 45px;
					}
				}
				.txt {
					font-size: 15px;
				}
			}
			.videoplay {
				display: none;
			}
		}
	}
	.callback_widget .callback_form {
		form {
			max-width: 320px;
			margin: 0 auto;
		}
		.cb_input {
			input, button {
				height: 50px;
			}
		}
		.cw_phone {
			h2 {
				font-size: 24px;
				margin: 0;
			}
			span {
				margin-left: 20px;
			}
		}
	}
	.ymap_widget {
		#map {
			height: 370px;
		}
	}
	.mfilter-content {
		.mfilter-checkbox, .mf_sliders > li {
			flex-wrap: wrap;
			.mfilter-heading-text {
				text-align: left;
				flex: 0 0 100%;
				margin-bottom: 10px;
			}
		}
		.mfilter-checkboxes {
			justify-content: center;
			padding: 5px;
			margin-left: -10px;
		}
	}
}