.buttons {
    @include clearfix;
    padding: 20px 0;
    .left {
        float: left;    }
    .right {
        float: right;
    }
}

.checkbox, .radio{
    padding-left:20px;
}