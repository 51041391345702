@media (max-width: $screen-md-max) {
	.callback_widget {
		background: $wh;
		padding: 45px;
		text-align: center;
		& > div {
			margin: 0 auto;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 16px 10px;
		font-size: 14px;
	}
	.producttabs {
		.product_block_h ul li a {
			font-size: 16px;
		}
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
			.cart {
				justify-content: left;
			}
			.btnrow.cart #button-cart {
				order: 1;
				margin-right: 0;
			}
		}
		.title-product, .product_block_h h3 {
			font-size: 20px;
		}
		.quantity-adder {
			margin-right: 30px;
		}
		.storage_widget {
			.sw_map .sw_capt, h2 {
				font-size: 16px;
			}
		}
	}
	.price.detail {
		ul {
			width: 100%;
		}
		.price {
			display: flex;
			flex: 0 1 400px;
			align-items: flex-end;
			white-space: nowrap;
			&-new {
				font-size: 28px;
				order: 2;
				.fa-rub {
					font-size: 25px;
				}
			}
			&-old {
				margin-right: 15px;
				order: 1;
				font-size: 18px;
			}
		}
	}
	body[class*="page-category"], body[class*="product-special"], body[class*="product-search"] {
		#content {
			padding: 0 15px;
		}
	}
	.product-grid .product-block {
		width: calc(100% / 2 - 22px);
	}
	.h1_container {
		.catthumb {
			display: none;
		}
		.circles {
			right: -155px;
		}
	}
	.tree-menu > ul {
		padding: 5px 0;
		> li {
			.accordion-heading span {
				padding: 6px 25px 6px 0;
			}
			a {
				padding: 5px 15px;
			}
		}
	}
	.logo-store {
		padding: 10px 15px;
		height: 100%;
	}
	.product-info-bg {
		& > div {
			width: 100%;
		}
		.image-container {
			margin-bottom: 30px;
		}
	}
	.product-filter {
		padding: 0;
		.filter-right {
			display: flex;
			.sort {
				padding-left: 0;
			}
			.limit {
				padding-right: 0;
			}
		}
	}
	.header-bottom {
		top: 130px;
	}
	#bs-megamenu ul {
		height: 120px;
		li {
			a {
				padding: 5px 0;
			}
		}
	}
	#searchtop input.form-control {
		height: 47px;
		padding: 8px 15px 8px 45px;
	}
	.product_videos .item-video {
		height: calc(50vw - 200px);
	}
	.information-contact {
		.ct_row {
			flex-wrap: wrap;
			.ct_col {
				&.ct_title {
					flex: 1 0 100%;
				}
			}
			&.ct_top {
				padding-top: 0;
			}
		}
		.ct_form {
			fieldset {
				flex-wrap: wrap;
				justify-content: center;
			}
			.btn {
				margin: 35px auto 0 auto;
			}
			.form-group {
				page-break-after: always;
				break-after: right;
				margin: 5px 0;
				width: 100%;
				max-width: 300px;
				&.err {
					margin-bottom: 20px;
				}
			}
		}
	}
	.information-warranty .pblock.citymap .scpoints ul {
		flex-wrap: wrap;
		padding: 10px 0;
		li {
			padding: 5px 30px;
			border: none;
		}
	}
	.information-aboutus {
		.about_block {
			.phone {
				font-size: 14px;
			}
			.abt_img {
				margin: 0 0 30px 30px;
				width: 33%;
			}
		}
		h2 {
			font-size: 26px;
		}
	}
	.information-deliveryandpayment {
		.about_block {
			padding: 5px 30px;
		}
		.dp_text_cont {
			margin-bottom: 10px;
			span {
				margin-top: 5px;
			}
		}
		.dp_row {
			flex-wrap: wrap;
		}
		.dp_col {
			flex: 0 1 100%;
			&.dp_col-3 {
				flex: 0 1 100%;
			}
		}
		.delivery .deliveryrussia {
			margin-top: 0;
		}
		.dp_frame {
			margin: -20px 0 20px 0;
		}
		.nalbeznal .dp_col {
			&:first-child, &:last-child {
				flex: 0 1 100%;
			}
		}
		.dp_questions {
			flex-wrap: wrap;
			h2 {
				flex: 0 1 100%;
				text-align: center;
			}
		}
		.flex-contacts {
			.dp_col-3 {
				flex: 0 1 50%;
				text-align: center;
			}
			.dp_text_cont {
				margin: 0;
				.dp_text {
					display: inline-block;
				}
			}
			.flexcont-capt {
				flex: 0 1 100%;
				margin: 0;
				.dp_text_cont {
					text-align: center;
				}
			}
		}
		.dp_email {
			.dp_col-3 {
				flex: 0 1 50%;
			}
		}
	}
	#powered {
		flex-wrap: wrap;
		.copyright span {
			width: auto;
		}
	}
	.cart_buttons {
		flex-wrap: wrap;
		justify-content: center;
		.cart_checkboxes {
			margin-bottom: 15px;
		}
	}
	#checkout-form {
		.form-group input {
			padding: 0 20px;
		}
		.cf_address {
			margin: 15px 0;
		}
		.cf_address, .cf_contacts {
			margin-bottom: 15px;
		}
		.cf_radio {
			margin-top: 15px;
			flex-wrap: wrap;
			& > div {
				width: 100%;
			}
			.payment_method {
				margin-top: 15px;
			}
		}
	}
	.buy_products ul li {
		flex-wrap: wrap;
		.cart-title {
			flex: 1 1 calc(100% - 140px);
		}
		.cart-title, .cart-img {
			margin-bottom: 15px;
		}
	}
	.owl-carousel {
		.owl-nav {
			.owl-prev, .owl-next {
				top: calc(50% - 17px);
				font-size: 26px;
				width: 35px;
				height: 35px;
				padding: 3px 12px;
			}
			.owl-prev {
				padding-left: 11px;
				left: -18px;
			}
			.owl-next {
				padding-left: 14px;
				right: -18px;
			}
		}
	}
	#f {
		padding: 15px 0;
	}
	body[class*="page-category"] .breadcrumbs {
		padding: 15px;
	}
	.mfilter-content .mfilter-checkbox {
		.mfilter-heading-text {
			flex: 0 0 180px;
		}
		.mfilter-options {
			flex: 0 1 auto;
		}
	}
	#main_cats {
		& > a {
			.pylesos {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				text-align: right;
				top: 55px;
				bottom: 55px;
				img {
					width: 70%;
				}
			}
		}
	}
	.product-markdown-view {
		.price.detail {
			.price {
				flex: unset;
			}
		}
	}
}

@media(max-width: 1080px) {
	.information-contact .ct_form .contacts_fieldset {
		flex-wrap: wrap;
	}
}

