@font-face {
	font-family: 'Gotham';
	src: url('/fonts/gotham/subset-GothamPro.eot');
	src: url('/fonts/gotham/subset-GothamPro.eot?#iefix') format('embedded-opentype'),
	url('/fonts/gotham/subset-GothamPro.woff2') format('woff2'),
	url('/fonts/gotham/subset-GothamPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/fonts/gotham/subset-GothamPro-Bold.eot');
	src: url('/fonts/gotham/subset-GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/gotham/subset-GothamPro-Bold.woff2') format('woff2'),
	url('/fonts/gotham/subset-GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('/fonts/helveticaneue/HelveticaNeueCyr-Light.eot');
	src: local('HelveticaNeueCyr-Light'),
	url('/fonts/helveticaneue/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
	url('/fonts/helveticaneue/HelveticaNeueCyr-Light.woff') format('woff'),
	url('/fonts/helveticaneue/HelveticaNeueCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}