@media (max-width: 480px) {
	.information {
		&-deliveryandpayment {
			.about_block {
				padding: 5px 15px;
				img {
					float: none !important;
					margin: 0 auto;
					display: block
				}
			}
			.flex-contacts .dp_col-3 {
				flex: 0 1 100%;
				padding-top: 0;
			}
			.dp_text_cont {
				font-size: 16px;
			}
			.dp_frame {
				padding: 15px;
			}
			.dp_text_cont span {
				margin-right: 15px;
			}
		}
		&-warranty {
			.pblock .scmaplist {
				padding: 0;
				& > ymaps {
					border-radius: 0 0 10px 10px;
				}
			}
			#content {
				padding: 0 30px;
				background: #e5e5e5;
			}
		}
		&-aboutus .about_block {
			p, div {
				font-size: 16px;
			}
		}
		.dp_email {
			.dp_text_cont {
				justify-content: center;
			}
		}
	}
	.flex-contacts .dp_phone .dp_phone_capt {
		margin: 0;
	}
	.h1_container {
		h1,.h1 {
			font-size: 18px;
		}
	}
	.page-product .product-info {
		padding: 10px;
	}
	.product-info {
		.title-product, .product_block_h h3 {
			font-size: 16px;
		}
		.btnrow.cart #button-cart {
			margin-top: 15px;
		}
		.product-meta .cart {
			justify-content: center;
			flex-wrap: wrap;
			.quantity-adder {
				page-break-after: always;
				break-after: right;
			}
		}
		.quantity-adder {
			margin-right: 0;
			flex: 1 0 100%;
			max-width: 160px;
		}
		.attributes li .att-val {
			font-size: 12px;
		}
	}
	.product-label.sale {
		top: 5px;
		left: 20px;
		width: 70px;
		height: 70px;
	}
	.storage_widget {
		margin: 0 20px;
	}
	#powered {
		justify-content: space-around;
		padding: 0 15px 25px;
		.copyright {
			flex: 0 1 auto;
			span {
				margin-left: 0;
				text-align: center;
			}
		}
	}
	.footer-center {
		padding: 0 15px 30px;
	}
	.callback_widget {
		.product_block_h h2 {
			font-size: 20px;
		}
		.callback_form {
			.cw_phone h2 {
				font-size: 16px;
			}
		}
	}
	.buy_products ul li {
		.cart-q {
			height: 40px;
			flex: 0 0 80px;
			.input-caption {
				display: none;
			}
			input {
				padding: 7px 10px 10px 10px;
			}
			.qc {
				margin: 4px 10px 2px 10px;
				span {
					line-height: 12px;
				}
			}
		}
		.cart-price, .cart-t {
			.value {
				font-size: 13px;
			}
		}
	}
	#coupon_form {
		h4 {
			font-size: 16px;
		}
		.form-group {
			margin-right: 10px;
			input {
				padding: 12px 10px;
				width: auto;
			}
		}
		#button-coupon {
			padding: 10px 15px;
		}
	}
	.product-info {
		.attributes_container {
			display: block;
		}
		.filter_icons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				margin: 0 5px;
				flex: 0 0 50px;
			}
		}
	}
	#main_cats > a {
		.bg {
			left: 30px;
			right: 30px;
		}
		.ttl {
			left: 70px;
			font-size: 18px;
		}
		.pylesos {
			right: 0;
		}
	}
	.success {
		.success-body {
			&, a {
				font-size: 14px;
			}
		}
		.success_button span {
			font-size: 11px;
		}
	}
	.information-deliveryandpayment .dp_text_cont {
		flex-wrap: wrap;
		img {
			margin-top: 0 !important;
		}
	}
	.sphinxsearch {
		width: calc(100vw - 30px);
		left: -50px;
	}
	#content {
		.sphinxsearch {
			display: none !important;
		}
	}
	#searchtop.srch {
		&, input.form-control {
			width: calc(100vw - 132px);
		}
	}
}

@media (max-width: 380px) {
	.logo-store svg {
		width: 100px;
	}
	#searchtop {
		right: 67px;
	}
}

@media (max-width: 360px) {
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(100% - 20px);
	}
	.tc-phone {
		width: calc(100% - 50px);
	}
	#callback_form {
		.modal_dialog {
			width: 300px;
		}
		h2 {
			font-size: 17px;
		}
		.cb_input {
			margin: 10px 0;
		}
	}
	#main_youget .product_block_h h2 br {
		display: none;
	}
	.callback_widget .cw_phone h2 {
		font-size: 26px;
	}
}

@media (max-width: 330px) {
	#maincats {
		display: none;
	}
}