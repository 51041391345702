@media (max-width: $screen-xs-max) {
	.breadcrumbs {
		padding: 0;
	}
	.page-product #sidebar-main {
		padding: 0 30px 10px 30px;
	}
	.tc-phone, .tc-worktime {
		padding-right: 0;
	}
	.tc-worktime {
		float: none;
	}
	.product-compare-header {
		right: 75px;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 32px);
		}
	}
	.h1_container {
		h1, .h1 {
			width: 100%;
			padding-right: 90px;
		}
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
		.product_block_h {
			margin-bottom: 0;
			ul {
				width: 100%;
				li {
					float: none;
					text-align: center;
					a {
						padding: 5px 0;
					}
					&.active a {
						font-size: 20px;
					}
				}
			}
		}
	}
	.callback_widget .product_block_h h2 {
		font-size: 30px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}
	.buy_products {
		ul {
			margin: 0;
			li {
				margin-bottom: 10px;
				.cart-img {
					flex: 0 0 80px;
					img {
						width: 80px;
					}
				}
				.cart-q {
					margin-right: 15px;
				}
				.cart-t, .cart-price {
					flex: 1 1 auto;
				}
				.cart-remove {
					position: absolute;
					right: 25px;
					a {
						width: 15px;
						height: 15px;
						stroke-width: 3px;
					}
				}
			}
		}
	}
	.cart-total-block {
		.total-right {
			padding: 0 0 0 15px;
			text-align: left;
		}
	}
	#checkout-form {
		padding: 20px 15px;
		.form-group {
			height: 50px;
			&.has-error {
				margin-bottom: 35px;
			}
		}
		.cf_contacts {
			flex-wrap: wrap;
			.form-group {
				width: calc((100% / 3) - 5px);
			}
		}
		.cf_address {
			.form-group {
				width: calc((100% / 2) - 5px);
			}
		}
	}
	#checkout-f {
		font-size: 30px;
		margin-bottom: 20px;
	}
	#button-order {
		padding: 15px 30px;
	}
	.header-top {
		.headercart {
			flex: 1 1 200px;
		}
		.topcontacts, .topcontacts.phone {
			flex: 1 1 235px;
		}
	}
	.logo-container {
		img {
			max-height: calc(13vw - 15px);
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
	}
	#bs-megamenu ul {
		height: 120px;
	}
	.tree-menu > ul > li a {
		padding: 5px 30px 5px 15px;
	}
	.s_controls {
		flex-wrap: wrap;
		& > div {
			width: 100%;
		}
		.selectric-wrapper {
			margin-left: 0;
		}
		.s_input label svg {
			max-width: 320px;
		}
		.checkbox_custom label {
			margin: 0 40px 20px 2px;

		}
	}
	.search_block {
		.dropdown-menu {
			&.autosearch {
				max-height: calc(100vh - 60px);
				overflow: auto;
				li {
					.as-title {
						padding: 10px 5px 30px 0;
					}
				}
			}
		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
		}
	}
	.footer-center {
		.footer-inside {
			flex-wrap: wrap;
		}
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.fancybox-opened {
		width: 90% !important;
		position: fixed !important;
		left: 5% !important;
		z-index: 999999 !important;
		top: 35% !important;
		.fancybox-skin {
			padding: 15px 0 !important;
		}
	}
	.fancybox-inner {
		width: 100% !important;
		height: auto !important;
	}
	.holiday_table {
		width: 100% !important;
		td {
			font-size: 15px !important;
		}
	}
	.information-contact .ct_form {
		h2 {
			font-size: 25px;
		}
		.product_block_h {
			margin-bottom: 15px;
		}
	}
	.col-md-12 .refine-search ul li {
		width: calc(33.3% - 20px);
	}
	.product_atts .tab-content .tab-pane ul li {
		font-size: 14px;
	}
	#callback_form {
		.modal_dialog {
			width: 400px;
		}
		.modal-content {
			padding: 30px 15px;
		}
		h2 {
			font-size: 20px;
		}
		.cb_input input {
			padding: 12px 20px
		}
		.close {
			top: 20px;
			right: 20px;
		}
	}
	.product-filter {
		flex-wrap: wrap;
		justify-content: right;
		.filter-right {
			order: 2;
		}
		#toggle_filters {
			order: 1;
		}
	}
	#main_cats {
		display: none;
	}
	.information-contact .ct_form .contacts_fieldset {
		flex-wrap: wrap;
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 0;
		br {
			line-height: 10px;
		}
		.success-body {
			padding: 45px 15px 50px 15px;
		}
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
	}
	.information-warranty .pblock.cities ul.cityaddr {
		flex-wrap: wrap;
		li {
			padding-right: 0;
		}
	}
	.price.detail .price {
		flex-wrap: wrap;
	}
	#main_youget > div.centerimg {
		display: none;
	}
	.product-filter .filter-right > div {
		padding: 0 25px;
	}
	.cart-total-block {
		.total-left {
			display: block;
			padding: 0 15px;
		}
	}
	#f {
		padding: 0;
	}
	ul.tags li {
		margin: 5px;
		padding: 10px 15px;
	}
}