.modal-open {
	overflow: hidden;
}

.modal {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10100;
	-webkit-overflow-scrolling: touch;
	&.fade .modal-dialog {
		@include translate(0, -25%);
		@include transition-transform(0.3s ease-out);
	}
	&.in .modal-dialog {
		@include translate(0, 0)
	}
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}

.modal-content {
	position: relative;
	background-color: $modal-content-bg;
	border: 1px solid $modal-content-border-color;
	border-radius: $border-radius-large;
	@include box-shadow(0 3px 9px rgba(0, 0, 0, .5));
	background-clip: padding-box;
	outline: 0;
}

.modal-backdrop {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-color: $modal-backdrop-bg;
	&.fade {
		@include opacity(0);
	}
	&.in {
		@include opacity($modal-backdrop-opacity);
	}
}

.modal-header {
	padding: $modal-title-padding;
	border-bottom: 1px solid $modal-header-border-color;
	min-height: ($modal-title-padding + $modal-title-line-height);
}

.modal-header .close {
	margin-top: -2px;
}

.modal-title {
	margin: 0;
	line-height: $modal-title-line-height;
}

.modal-body {
	position: relative;
	padding: $modal-inner-padding;
}

.modal-footer {
	padding: $modal-inner-padding;
	text-align: right;
	border-top: 1px solid $modal-footer-border-color;
	@include clearfix;
	.btn + .btn {
		margin-left: 5px;
		margin-bottom: 0;
	}
	.btn-group .btn + .btn {
		margin-left: -1px;
	}
	.btn-block + .btn-block {
		margin-left: 0;
	}
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: $screen-sm-min) {
	.modal-dialog {
		width: $modal-md;
		margin: 30px auto;
	}
	.modal-content {
		@include box-shadow(0 5px 15px rgba(0, 0, 0, .5));
	}
	.modal-sm {
		width: $modal-sm;
	}
}

@media (min-width: $screen-md-min) {
	.modal-lg {
		width: $modal-lg;
	}
}
