[data-simplebar] {
	position: relative;
	z-index: 0;
	overflow: hidden !important;
	max-height: inherit;
	-webkit-overflow-scrolling: touch;
}

[data-simplebar="init"] {
	display: flex;
}

.simplebar-scroll-content {
	overflow-x: hidden !important;
	overflow-y: scroll;
	min-width: 100% !important;
	max-height: inherit !important;
	box-sizing: content-box !important;
	margin-bottom: 0 !important;
}

.simplebar-content {
	overflow: hidden !important;
	box-sizing: border-box !important;
	min-height: 100% !important;
	margin-right: -27px !important;
	li {
		padding-right: 10px;
	}
}

.simplebar-track {
	z-index: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 11px;
}

.simplebar-scrollbar {
	position: absolute;
	right: 2px;
	width: 6px;
	min-height: 10px;
}

.simplebar-scrollbar:before {
	position: absolute;
	content: "";
	background: $bc;
	border-radius: 7px;
	left: 0;
	right: 0;
	opacity: 0;
	@include transition(opacity 0.2s linear);
}

.simplebar-track:hover .simplebar-scrollbar:before,
.simplebar-track .simplebar-scrollbar.visible:before {
	opacity: 1;
	@include transition(opacity 0.2s linear);
}

.simplebar-track.vertical {
	top: 0;
	margin-bottom: 17px;
}

.simplebar-track.vertical .simplebar-scrollbar:before {
	top: 2px;
	bottom: 2px;
}

.simplebar-track.horizontal {
	left: 0;
	width: auto;
	height: 11px;
}

.simplebar-track.horizontal .simplebar-scrollbar:before {
	height: 100%;
	left: 2px;
	right: 2px;
}

.horizontal.simplebar-track .simplebar-scrollbar {
	right: auto;
	top: 2px;
	height: 7px;
	min-height: 0;
	min-width: 10px;
	width: auto;
}