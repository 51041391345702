.paging {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pagination {
	display: inline-block;
	padding: 10px;
	margin: 0;
	> li {
		display: inline-block;
		position: relative;
		width: 45px;
		text-align: center;
		margin: 5px 8px 0 0;
		svg {
			width: 100%;
			stroke: $blue;
			stroke-width: 1px;
			fill: none;
			position: absolute;
			left: 0;
			top: 0;
		}
		> a, > span {
			position: relative;
			line-height: 41px;
			width: 40px;
			height: 34px;
			text-decoration: none !important;
			text-align: center;
			color: $blue;
			@include h();
			font-size: 15px;
			padding: 10px 15px;
		}
		&:first-child {
			> a, > span {
				margin-left: 0;
			}
		}
	}
	> li > a, > li > span {
		&:hover, &:focus {
			color: $blue;
		}
	}
	> .active {
		svg {
			stroke: $dg;
		}
		> a, > span {
			&, &:hover, &:focus {
				color: $dg;
				cursor: default;
			}
		}
	}
}