.list-unstyled {
	list-style: none;
	padding-left: 0;
}

.alert-box-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-box-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-box-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-box-error {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: $wh;
	border: none;
	left: 50%;
	top: 22%;
	z-index: 25000;
	position: fixed;
	.success-body {
		color: $bk !important;
		font-size: 18px;
		z-index: 15350;
		position: relative;
		padding: 50px 50px 90px;
		background: $wh;
		border-radius: 10px;
	}
	.success-wall {
		background: rgba(0, 0, 0, .4);
		position: fixed;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 15300;
	}
	a {
		text-decoration: underline !important;
		font-size: 18px;
		color: $blue;
		&:hover {
			color: $blue;
			text-decoration: underline !important;
		}
	}
	.close {
		width: 20px;
		height: 20px;
		stroke: $tg;
		stroke-width: 2px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
	.success_button {
		text-decoration: none !important;
		@include transition(0.2s all);
		background: none;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: $bk !important;
		padding: 10px 24px;
		span {
			font-size: 13px;
			@include h();
			text-decoration: none;
		}
		&:hover {
			background: none;
			text-decoration: none !important;
		}
	}
}

.send-error {
	padding: 20px;
	box-shadow: 0 0 15px rgba(0,0,0,.2);
	h2 {
		margin: 20px;
	}
	#send-user-error {
		margin: 0 auto;
	}
	#send-error-form {
		margin: 30px 0;
	}
	textarea {
		margin: 5px 0;
		min-height: 50px;
		padding: 5px;
		border: 2px solid $tg;
		border-radius: 12px;
		&:active, &:focus {
			outline: none;
		}
	}
}