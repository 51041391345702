#offcanvasmenu {
	color: $blue;
	@include hl();
}

.tree-menu {
	padding: 0;
	font-family: $h;
	& > ul {
		padding: 15px 0;
		margin: 0;
		list-style: none;
		& > li {
			position: relative;
			&:first-child {
				border-top: none;
			}
			.accordion-heading {
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 999;
				span {
					width: 40px;
					height: 43px;
					padding: 12px 0;
					display: inline-block;
					svg {
						@include transform-origin(4px 6px);
						@include transform(rotate(90deg));
						@include fade();
					}
					&.collapsed {
						svg {
							@include transform(rotate(0));
						}
					}
				}
			}
			a {
				padding: 10px 45px;
				color: $bk;
				text-transform: uppercase;
				display: inline-block;
				width: 100%;
				font-size: 14px;
				text-decoration: none;
			}
		}
		ul {
			padding: 0;
			list-style: none;
			background: $tc;
			@include box-shadow(3px 7px 7px rgba(0, 0, 0, .2) inset);
			li a {
				padding: 10px 45px 10px 60px;
			}
		}
	}
}

#bs-megamenu {
	ul {
		margin: 0;
		padding: 0 45px;
		@include flex();
		flex-wrap: wrap;
		flex-direction: column;
		height: 140px;
		li {
			flex: 0 0 auto;
			display: inline-block;
			a {
				color: $blue;
				font-size: 16px;
				font-family: $f;
				font-weight: 300;
				text-decoration: underline;
				padding: 10px 0;
			}
		}
	}
}