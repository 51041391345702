@media (max-width: $screen-sm-max) {
	html.menuopened {
		overflow-y: hidden;
	}
	header, .header-top, .header-bottom {
		width: 100%;
	}
	.header-bottom {
		top: 70px;
	}
	header {
		height: 70px;
		min-height: unset;
		bottom: auto;
		overflow: hidden;
		@include fade();
		.header-bottom {
			height: auto;
		}
		&.active {
			overflow-y: auto;
			height: 100%;
			min-height: 100vh;
		}
		.logo-store {
			a {
				margin: 0 auto;
			}
			span {
				display: none;
			}
		}
		.logo-container {
			height: 70px;
			position: relative;
		}
		.mobphone {
			display: inline-block;
			position: absolute;
			z-index: 9;
			width: 30px;
			height: 30px;
			left: 70px;
			top: 19px;
			svg {
				width: 30px;
				height: 30px;
				path {
					vector-effect: non-scaling-stroke;
				}
			}
		}
		.canvas-menu {
			position: absolute;
			z-index: 10;
			left: 20px;
			top: calc(50% - 15px);
			width: 30px;
			height: 30px;
			padding: 0;
			border: none;
			background: none;
			&.cross {
				#cm1 {
					transform: translate(-3px, -6px) rotate(45deg);
				}
				#cm3 {
					transform: translate(-2px, 5px) rotate(-45deg);
				}
				#cm2 {
					transform: scaleX(0);
				}
			}
			svg {
				width: 100%;
				stroke: $bk;
				stroke-width: 3px;
				fill: none;
				#cm2 {
					transform-origin: center center;
				}
				path {
					vector-effect: non-scaling-stroke;
					@include fade();
					transform-origin: left center;
				}
			}
			&:active, &:focus {
				outline: none;
			}
		}
	}
	body.checkout-buy {
		#cartbox {
			display: block;
		}
	}
	#bs-megamenu ul {
		height: 85px;
	}
	.tc-phone {
		float: left;
		width: 330px;
	}
	.tc-worktime {
		float: right;
		width: 330px;
		margin-top: 2px;
	}
	#cartbox {
		padding: 0;
		position: fixed;
		z-index: 9999;
		height: 70px;
		#cart {
			width: 30px;
			height: 30px;
			display: inline-block;
			top: calc(50% - 23px);
			position: absolute;
			right: 25px;
			.cart-inner {
				display: none;
			}
			a {
				width: 100%;
				height: 100%;
			}
		}
		.headercart {
			svg:first-child, .wrap-cart {
				display: none;
			}
			.carticon {
				width: 100%;
				height: 100%;
				top: auto;
				left: auto;
			}
		}
		.product-compare-header {
			width: auto;
			top: 78px;
			right: -12px;
			#compare-total {
				display: none;
			}
			.badge {
				left: 30px;
			}
		}
	}
	#searchtop {
		border: none;
		width: 40px;
		height: 70px;
		position: absolute;
		z-index: 999;
		right: 75px;
		top: 0;
		input.form-control {
			font-size: 16px;
			padding: 0;
			width: 0;
			height: calc(100% - 1px);
			background: $wh;
			opacity: 0;
			@include fade();
			position: absolute;
			right: 0;
		}
		&.srch {
			width: calc(100% - 190px);
			input.form-control {
				padding: 8px 15px;
				width: calc(100vw - 190px);
				opacity: .95;
			}
		}
		.btn-search {
			margin: 0;
		}
	}
	#page {
		padding-left: 0;
		padding-top: 70px;
	}
	body[class*="information-"] h1 {
		padding: 15px 0 21px 15px;
	}
	.h1_container {
		padding: 0;
		min-height: 100px;
		.circles {
			width: 300px;
			right: -50px;
			top: -10px;
		}
		h1,.h1 {
			padding: 15px 0 15px 15px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3 - 23px);
			.bottom {
				padding: 0 30px 30px 30px;
			}
			.action > div {
				padding-right: 2px;
			}
		}
	}
	.breadcrumb {
		margin: 0;
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.about_block {
		padding: 15px;
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 10px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {
				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.product-info {
		.storage_widget {
			.sw_bottom {
				flex-wrap: wrap;
				padding: 15px 15px 0;
				.sw_btn {
					flex: 1;
					margin-left: 0;
					button {
						margin: 15px auto 0 auto;
					}
				}
			}
		}
	}
	.product_videos .item-video {
		height: calc(50vw - 30px);
	}
	.product_atts {
		.nav-tabs {
			flex-wrap: wrap;
			li {
				flex: 1 0 100%;
				a {
					padding: 15px 0;
				}
			}
		}
	}
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
	.product-grid, .products-owl-carousel {
		.product-block .bottom .iframe-link {
			display: none;
		}
	}
	.owl-carousel {
		.owl-nav {
			.owl-prev, .owl-next {
				top: calc(50% - 13px);
				font-size: 20px;
				width: 25px;
				height: 25px;
				padding: 2px 10px;
			}
			.owl-prev {
				padding-left: 10px;
				left: -12px;
			}
			.owl-next {
				padding-left: 8px;
				right: -12px;
			}
		}
	}
	.imgthumbs.owl-carousel {
		padding: 0 20px;
		.owl-nav {
			.owl-next {
				right: 15px;
			}
			.owl-prev {
				left: 15px;
			}
		}
	}
	body.common-home .btn-default.btn-cart path {
		stroke: $bk;
	}
	.information-contact .ct_form .contacts_fieldset {
		flex-wrap: nowrap;
	}
}